// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-templates-about-page-tsx": () => import("./../src/templates/about-page.tsx" /* webpackChunkName: "component---src-templates-about-page-tsx" */),
  "component---src-templates-podcast-episode-tsx": () => import("./../src/templates/podcast-episode.tsx" /* webpackChunkName: "component---src-templates-podcast-episode-tsx" */)
}

